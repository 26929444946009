export default editor => {
    editor.DomComponents.addType('paragraph', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-paragraph')) {
                return { type: 'paragraph' }
            }
            return false
        },
        extend: 'text',
        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'p',
                name: 'Text',
                draggable: '[data-gjs-type=column]',
                attributes: {
                    class: 'et-paragraph'
                },
                highlightable: true,
                hoverable: true,
                content: `Lorem ipsum dolor....`
            }
        }
    });
}
