export default editor => {
    editor.DomComponents.addType('column', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-column')) {
                return { type: 'column' }
            }
            return false
        },

        // Model definition
        model: {
            // Default properties
            defaults: {
                name: 'Spalte',
                draggable: '[data-gjs-type=columns]',
                tagName: 'div',
                attributes: {
                    class: 'et-column'
                },
                traits:[
                    {
                        type: 'select', // Type of the trait
                        label: 'Breite', // The label you will see in Settings
                        name: 'clsWidth', // The name of the attribute/property to use on component
                        options: [
                            { id: '', name: 'Automatisch'},
                            { id: 'is-6', name: '50%'},
                            { id: 'is-3', name: '25%'},
                            { id: 'is-4', name: '33%'},
                            { id: 'is-8', name: '66%'},
                        ],
                        changeProp: 1
                    },
                    {
                        type: 'select',
                        label: 'Ausrichtung',
                        name: 'style',
                        options: [
                            {
                                id: 'text-align:left;', name: 'Links'
                            },
                            {
                                id: 'text-align:center;', name: 'Mitte'
                            },
                            {
                                id: 'text-align:left;', name: 'Rechts'
                            }
                        ]
                    }
                ]
            },

            init() {
                this.on('change:clsWidth', this.handleWidthChange);
            },

            handleWidthChange() {
                console.log('Input type changed to: ', this.props().clsWidth)
                this.removeClass(['is-3','is-4','is-6','is-8'])
                console.log(this.props)
                this.addClass(this.props().clsWidth)
            }
        }
    });
}
