export default editor => {
    editor.DomComponents.addType('picture', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-picture')) {
                return { type: 'picture' }
            }
            return false
        },
        extend: 'image',
        // Model definition
        model: {
            // Default properties
            defaults: {
                name: 'Bild',
                draggable: '[data-gjs-type=column]',
                attributes: {
                    class: 'et-picture'
                }
            }
        }
    });
}
