export default editor => {
    editor.DomComponents.addType('columns', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-columns')) {
                return { type: 'columns' }
            }
            return false
        },

        // Model definition
        model: {
            // Default properties
            defaults: {
                name: 'Zeile',
                draggable: '[data-gjs-type=body]',
                tagName: 'div',
                attributes: {
                    class: 'et-columns'
                },
                components: [
                    {type:"column"}
                ]
            }
        }
    });
}
