export default editor => {
    editor.DomComponents.addType('button', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-button')) {
                return { type: 'button' }
            }
            return false
        },

        // Model definition
        model: {
            // Default properties
            defaults: {
                name: 'Button',
                tagName: 'a',
                draggable: '[data-gjs-type=column]',
                attributes: {
                    class: 'et-button button'
                },
                content: `Button`,
                editable: true

            }
        }
    });
}
