export default editor => {
    editor.DomComponents.addType('container', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-container')) {
                return { type: 'container' }
            }
            return false
        },
        extend: 'default',
        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'p',
                name: 'Container',
                draggable: false,
                attributes: {
                    class: 'et-container max-w-4xl mx-auto'
                },
                highlightable: false,
                hoverable: false
            }
        }
    });
}
