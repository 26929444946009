export default editor => {
    editor.DomComponents.addType('radio', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-radio')) {
                return { type: 'radio' }
            }
            return false
        },
        extend: 'default',
        // Model definition,
        init(){

        },
        model: {
            // Default properties
            defaults: {
                tagName: 'label',
                name: 'Radio-Button',
                draggable: '[data-gjs-type=radios]',
                attributes: {
                    class: 'et-radio',
                    type: 'radio'
                },
                components:[
                    {
                        tagName: 'input',
                        attributes: {
                            type: 'radio',
                        },
                        removable: false,
                        selectable: false,
                        hoverable: false,
                        layerable: false,
                        editable: false,
                        resizable: false,
                        copyable: false,
                        highlightable:false,
                        badgable: false,
                        droppable: false,
                        draggable: false,

                    },
                    {
                        type:'text',
                        content: 'Radio-Button',
                        tagName: 'span'
                    }
                ],
                highlightable: true,
                hoverable: true,
                traits:[
                    {
                        label: 'Platzhalter', // The label you will see in Settings
                    }
                ]
            }
        }
    });
}
