import { renderSessions } from "../../../../common/lp-render";

export default editor => {
    editor.DomComponents.addType('sessions', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-sessions')) {
                return { type: 'sessions' }
            }
            return false
        },
        extend: 'default',
        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'div',
                name: 'Sessions',
                draggable: '[data-gjs-type=body]',
                attributes: {
                    class: 'et-sessions'
                },
                highlightable: false,
                hoverable: false
            },
        },
        view: {
            tagName: 'div',
            onRender() {
                renderSessions(this.el, null);
            }
        }
    });
}
