export default editor => {
    editor.DomComponents.addType('body', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-body')) {
                return { type: 'body' }
            }
            return false
        },

        // Model definition
        model: {
            // Default properties
            defaults: {
                name: 'Inhalte',
                tagName: 'section',
                droppable: true,
                draggable: false,
                removable: false,
                copyable: false,
                attributes: {
                    class: 'section et-body'
                },
                components: [
                    {type:"columns"}
                ]
            }
        }
    });
}
