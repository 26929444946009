export default {
    type: 'link',
    draggable: false,
    removable: false,
    copyable: false,
    dropable: false,
    attributes: {
        class: ''
    },
    components: [
        {
            type: 'image',
            draggable: false,
            removable: false,
            dropable: false,
            copyable: false,
            attributes: {
                class: 'logo'
            },
            src: 'https://mailing.sparkassen-finanzportal.de/public/a_4760_VH8SW/webspace/Vorlagen/2018/01_survey/img/logo.png',
            fallback: 'https://mailing.sparkassen-finanzportal.de/public/a_4760_VH8SW/webspace/Vorlagen/2018/01_survey/img/logo.png'
        }
    ]
}
