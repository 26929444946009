export default editor => {
    editor.DomComponents.addType('subheadline', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-subheadline')) {
                return { type: 'subheadline' }
            }
            return false
        },
        extend: 'text',
        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'p',
                name: 'Subheadline',
                draggable: '[data-gjs-type=body],[data-gjs-type=column]',
                attributes: {
                    class: 'et-subheadline'
                },
                highlightable: true,
                hoverable: true,
                content: `Subheadline`
            }
        }
    });
}
