import footerParagraph from './footer/footer-paragraph'
import footerLinks from './footer/footer-links'
import footerDisclaimer from './footer/footer-disclaimer'

export default editor => {
    editor.DomComponents.addType('footer', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-footer')) {
                return { type: 'footer' }
            }
            return false
        },

        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'section',
                droppable: false,
                draggable: false,
                removable: false,
                copyable: false,
                attributes: {
                    class: 'et-footer'
                },
                components: [
                    footerParagraph,
                    footerLinks,
                    footerDisclaimer
                ]
            }
        }
    });
}
