export default editor => {
    editor.DomComponents.addType('headline', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-headline')) {
                return { type: 'headline' }
            }
            return false
        },
        extend: 'text',
        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'p',
                name: 'Headline',
                draggable: '[data-gjs-type=body]',
                attributes: {
                    class: 'et-headline'
                },
                highlightable: true,
                hoverable: true,
                content: `Headline`
            }
        }
    });
}
