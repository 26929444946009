export default {
    type: 'text',
    draggable: false,
    removable: false,
    copyable: false,
    dropable: false,
    attributes: {
        class: 'et-footer-links'
    },
    content: `<a href="http://www.example.com" target="_blank">AGB</a><a href="http://www.example.com" target="_blank">Datenschutz</a><a href="http://www.example.com" target="_blank">Impressum</a><a href="http://www.example.com" target="_blank">Kontakt</a>`
}