export default {
    type: 'text',
    draggable: false,
    removable: false,
    copyable: false,
    dropable: false,
    attributes: {
        class: 'et-footer-disclaimer'
    },
    content: `Die technische Umsetzung dieser Anmeldung erfolgte durch die <a href="https://www.sparkassen-finanzportal.de/" target="_blank">Sparkassen-Finanzportal GmbH</a>.`
}