const getNiceTimeRange = (slot) => {
  const s = new Date(slot.start);
  const e = new Date(slot.end);

  return `${`${s.getHours()}`.padStart(2, '0')}:${`${s.getMinutes()}`.padStart(2, '0')} - ${`${e.getHours()}`.padStart(2, '0')}:${`${e.getMinutes()}`.padStart(2, '0')}`
}

const getNiceDate = (date) => {
  const parsedDate = new Date(date);

  return `${`${parsedDate.getDay()}`.padStart(2, '0')}.${`${parsedDate.getMonth() + 1}`.padStart(2, '0')}.${parsedDate.getFullYear()}`;
}

const appendHiddenInput = (form, name, value) => {
  const i = document.createElement('input')

  i.name = name;
  i.setAttribute('type', 'hidden');
  i.setAttribute('value', value);

  form.append(i);
}

export const renderSessions = (sessionsRoot, $store) => {
  console.log('rendering sessions in', sessionsRoot, window.$eventId);

  fetch(`https://api-test.events.sendnode.com/v1/events/${window.$eventId}/simplifiedsessions`).then((response) => {
    if (response.ok) {
      return response.json();
    }

    return Promise.resolve([]);
  }).then(sessions => {
    console.log(sessions);

    const form = document.createElement('form');

    form.method = 'POST';
    form.action = 'https://login.mailingwork.de/optin/optin/execute'
    form.acceptCharset = 'utf-8';

    // sessions header

    const sessionsHeader = document.createElement('div');

    sessionsHeader.textContent = `Sessions (${sessions.length})`;

    sessionsHeader.className = 'et-sessions-header';

    // form.append(sessionsHeader);

    if (sessions.length > 0) {
      appendHiddenInput(form, 'account_id', '25358');
      appendHiddenInput(form, 'account_code', 'WHDvz');

      appendHiddenInput(form, 'optinsetup_id', sessions[0].event.mwOptInSetupId);
      appendHiddenInput(form, 'optinsetup_code', sessions[0].event.mwOptInSetupCode);

      appendHiddenInput(form, 'ic', 17);
    }

    if ($store) {
      const recipient = $store.state.landingpage.mwRecipient;
      const fields = Object.keys(recipient);

      for (const k of fields) {
        appendHiddenInput(form, `fields[${k}]`, recipient[k]);
      }
    }

    for (const session of sessions) {
      const wrapper = document.createElement('div');

      wrapper.className = 'et-session';
      // checkbox

      const checkboxWrapper = document.createElement('div');

      checkboxWrapper.className = 'et-session-checkbox-wrapper';

      const inputCheckbox = document.createElement('input');

      inputCheckbox.type = 'checkbox';

      checkboxWrapper.append(inputCheckbox);

      wrapper.append(checkboxWrapper);

      const textWrapper = document.createElement('div');

      textWrapper.className = 'et-session-text-wrapper';

      // session name

      const textName = document.createElement('div');

      textName.className = 'et-session-name';

      textName.textContent = session.name;

      textWrapper.append(textName);

      const timeWrapper = document.createElement('div');

      timeWrapper.className = 'et-session-time-wrapper';

      // date

      const textDate = document.createElement('div');

      textDate.className = 'et-session-date';

      let date = '';

      if (session.date) {
        date = getNiceDate(session.date)
      }

      textDate.textContent = date;

      timeWrapper.append(textDate);

      // time

      const textTime = document.createElement('div');

      textTime.className = 'et-session-time';

      textTime.textContent = `${session.timeslot ? getNiceTimeRange(session.timeslot) + ' Uhr' : ''}`;

      timeWrapper.append(textTime);

      textWrapper.append(timeWrapper);

      // location

      const locationWrapper = document.createElement('div');

      locationWrapper.className = 'et-session-location-wrapper';

      const locationLocationName = document.createElement('div');
      const locationAddressText = document.createElement('div');
      const locationCityText = document.createElement('div');

      locationLocationName.textContent = session.location.name;
      locationAddressText.textContent = session.location.address.address;
      locationCityText.textContent = session.location.address.zip + ' ' + session.location.address.city;

      locationWrapper.append(
        locationLocationName,
        locationAddressText,
        locationCityText,
      );

      textWrapper.append(locationWrapper);

      wrapper.append(textWrapper);

      form.append(wrapper);
    }

    const submitButtonWrapper = document.createElement('div');

    submitButtonWrapper.className = 'et-sessions-submit-wrapper';

    const submitButton = document.createElement('button');

    submitButton.className = 'et-button';
    submitButton.type = 'submit';
    submitButton.textContent = 'Absenden';

    submitButtonWrapper.append(submitButton);

    form.append(submitButtonWrapper)

    sessionsRoot.append(form);
  })
};
