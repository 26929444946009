export default editor => {
    editor.DomComponents.addType('radios', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-radios')) {
                return { type: 'radios' }
            }
            return false
        },
        extend: 'default',
        // Model definition
        init(){
            this.on('change:name', this.handleName)
        },
        handleName(){
            this.components().at(0).addAttributes({name:this.get('name')})
        },
        addChild(){
            this.components().at(0).append({type:'radio'})
        },
        model: {
            // Default properties
            defaults: {
                tagName: 'div',
                name: 'Radio-Button-Gruppe',
                draggable: '[data-gjs-type=column]',
                attributes: {
                    class: 'et-radios',
                    'data-group-type': 'radio',
                },
                highlightable: true,
                hoverable: true,
                traits:[
                    {
                        label: 'Name',
                        name:'name',
                        changeProp:true
                    },{
                        type: 'button',
                        // ...
                        text: 'Click me',
                        full: true, // Full width button
                        command: editor => {
                            const parent = editor.getSelected().get('components').first().collection
                            parent.add({type: 'radio'})
                        }

                    }
                ],
                components:[
                    {type: 'radio'}
                ]
            }
        }
    });
}
